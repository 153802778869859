import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SharedHeader from 'shared/shared-component/components/Header';
import { useOktaAuth } from '@okta/okta-react';

import locale from 'config/locale';
import {
  DASHBOARD_BASE_PATH,
  HELP_PATH,
  GET_STARTED_PATH,
} from 'shared/constants/paths';
import LocalStorageProxy from 'lib/localStorageProxy';
import { toLoginOnSignout } from 'lib/pathHelper';
import { getCustomMenu, showPreferencesModal } from 'lib/goalLoanHelper';
import { isApplyNow } from 'lib/envHelper';
import { useModal } from 'contexts/ModalContext';

import * as goalLoanApplicationSelectors from 'selectors/goalLoanApplicationSelectors';
import { isNonLMG } from 'selectors/aggregatorSelectors';
import goalLoanApplicationActions from 'actions/goal/goalLoanApplicationActions';
import UIActions from 'actions/UIActions';

import LoanMarketLogo from 'components/LoanMarketLogo/LoanMarketLogo';
import styles from './Header.css';

import { getAdvisorOrAdvisorOrg } from 'lib/prepareStore';
import { logoutUtil } from 'lib/utils/common';

const messages = defineMessages({
  myDashboard: {
    id: 'Header.myDashboard',
    defaultMessage: 'My Dashboard',
  },
  logInText: {
    id: 'Header.logIn.Text',
    defaultMessage: 'Log In',
  },
  logOutText: {
    id: 'Header.logOut.Text',
    defaultMessage: 'Log Out',
  },
  callText: {
    id: 'Header.call.Text',
    defaultMessage: 'Call',
  },
});

export const Header = ({
  intl: { formatMessage },
  advisor,
  advisorOrg,
  showNavigation,
  inverse,
  theme,
  countryCode,
  showLogin,
  showRightContent,
  goal,
  applications,
  switchGoalLoanApp,
  active,
  showCallSection,
  pushSpinner,
  popSpinner,
  goalPreferences,
  updateGoalPreferences,
  showMenu,
  isNonLMG,
  showHeaderLogoOnly,
  setSessionStatus,
}) => {
  const { authState, oktaAuth } = useOktaAuth();
  const intlMessages = {
    myDashboard: formatMessage(messages.myDashboard),
    logOutText: formatMessage(messages.logOutText),
    logInText: formatMessage(messages.logInText),
    callText: formatMessage(messages.callText),
  };
  const modal = useModal();

  const loanApplicationId = LocalStorageProxy.primaryLoanApplicationId;
  const isBrokerLogin = () =>
    !!LocalStorageProxy.loginAsClientId ||
    !!LocalStorageProxy.loginAsLoanApplicationId;

  const logout = async (isOwnBrand) => {
    const logoutRedirectUri = byobLogoutRedirectUrl(isOwnBrand, isNonLMG);

    pushSpinner('LOGOUT');

    await logoutUtil(logoutRedirectUri, oktaAuth, false, isOwnBrand, {
      setSessionStatus,
    });

    popSpinner('LOGOUT');

    if (locale.isAU && !isApplyNow() && !isOwnBrand) {
      window.location.assign(`${window.LM_CONFIG.LM_DOMAIN}`);
    }

    if (isOwnBrand || isApplyNow()) {
      window.location.href = logoutRedirectUri;
      window.location.reload();
    }
  };

  const getGoalCustomMenu = () =>
    getCustomMenu(
      active,
      applications,
      switchGoalLoanApp,
      showPreferencesModal(modal, goalPreferences, updateGoalPreferences),
    );

  const renderLogo = (isInverse) => {
    return isApplyNow() ? (
      React.Fragment
    ) : (
      <LoanMarketLogo className={styles.loanMarketLogo} inverse={isInverse} />
    );
  };

  const platform = {
    name: 'Loan Market',
    dashboardPath: DASHBOARD_BASE_PATH,
    loginPath: toLoginOnSignout(loanApplicationId),
    logoutAction: logout,
    renderLogo,
  };
  platform.phoneNumberFormatted = locale.data.phoneNumberFormatted;
  platform.phoneNumber = locale.data.phoneNumber;

  const validateLogo =
    advisorOrg && advisorOrg.brandLogoUrl ? (
      <img
        src={advisorOrg.brandLogoUrl}
        className={styles.loanMarketLogo}
        alt='Logo'
      />
    ) : (
      renderLogo(false)
    );

  const isLoggedIn = authState ? authState.isAuthenticated : false;
  const validateHeader = showHeaderLogoOnly ? (
    <div className={styles.validateLogo}>{validateLogo}</div>
  ) : (
    <SharedHeader
      advisor={advisor}
      advisorOrg={advisorOrg}
      showNavigation={showNavigation}
      inverse={inverse}
      theme={theme}
      countryCode={countryCode}
      logoutAction={logout}
      platform={platform}
      isLoggedIn={isLoggedIn}
      messages={intlMessages}
      showLogin={showLogin}
      showRightContent={showRightContent}
      showCallSection={showCallSection}
      showAdvisorImageOnly={goal}
      customNavigation={goal ? getGoalCustomMenu() : {}}
      showMenu={showMenu}
      hideLogoutButton={isBrokerLogin()}
    />
  );
  return <div className={styles.rootWrapper}>{validateHeader}</div>;
};

Header.propTypes = {
  intl: intlShape.isRequired,
  advisor: PropTypes.object,
  advisorOrg: PropTypes.object,
  showNavigation: PropTypes.bool,
  inverse: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  inverseHeader: PropTypes.bool,
  theme: PropTypes.string,
  countryCode: PropTypes.string.isRequired,
  showLogin: PropTypes.bool,
  showRightContent: PropTypes.bool,
  goal: PropTypes.bool,
  applications: PropTypes.instanceOf(Array),
  switchGoalLoanApp: PropTypes.func,
  active: PropTypes.number,
  showCallSection: PropTypes.bool,
  pushSpinner: PropTypes.func,
  popSpinner: PropTypes.func,
  goalPreferences: PropTypes.object,
  updateGoalPreferences: PropTypes.func,
  showMenu: PropTypes.bool,
  isNonLMG: PropTypes.bool,
  setSessionStatus: PropTypes.func,
};

Header.defaultProps = {
  countryCode: 'AU',
  advisor: {},
  advisorOrg: {},
  inverse: false,
  inverseHeader: false,
  showLogin: true,
  goal: false,
  showCallSection: true,
};

const byobLogoutRedirectUrl = (isOwnBrand, isNonLMG) => {
  const { advisorOrgId, advisorOrgSlug, advisorUuid } = getAdvisorOrAdvisorOrg(
    window.location.pathname,
  );
  const pcfRedirectPath = isNonLMG ? HELP_PATH : '';
  const orgId = advisorOrgId ? `/${advisorOrgId}` : '';
  const slug = advisorOrgSlug ? `/${advisorOrgSlug}` : '';
  const uuid = advisorUuid ? `/${advisorUuid}` : '';
  const path =
    isApplyNow() && !isOwnBrand ? `${GET_STARTED_PATH}` : pcfRedirectPath;

  return `${window.location.origin}${orgId}${slug}${uuid}/#${path}`;
};

const mapStateToProps = (state) => ({
  applications: goalLoanApplicationSelectors.applications(state),
  active: goalLoanApplicationSelectors.activeApplication(state),
  goalPreferences: goalLoanApplicationSelectors.goalPreferences(state),
  isNonLMG: isNonLMG(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      switchGoalLoanApp: goalLoanApplicationActions.switchGoalLoanApp,
      updateGoalPreferences: goalLoanApplicationActions.updateGoalPreferences,
      pushSpinner: UIActions.pushSpinner,
      popSpinner: UIActions.popSpinner,
      setSessionStatus: UIActions.setSessionStatus,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Header));
