export const DOCUMENT_CATEGORY_IDENTIFICATION_KEY = 'Identification';
export const DOCUMENT_CATEGORY_EQUITY_KEY = 'Equity / Bank Statements';
export const DOCUMENT_CATEGORY_SECURITY_KEY = 'Security';
export const DOCUMENT_CATEGORY_INCOME_KEY = 'Income';
export const DOCUMENT_CATEGORY_OTHERS_KEY = 'Other';

export const SUB_CATEGORY_PERSONAL_CURRENT_ACCOUNT = {
  key: 'personalCurrentAccount',
  name: 'Personal Current Account',
  category: DOCUMENT_CATEGORY_EQUITY_KEY,
};

export const SUB_CATEGORY_PASSPORT = {
  key: 'passport',
  name: 'Passport',
  category: DOCUMENT_CATEGORY_IDENTIFICATION_KEY,
};

export const SUB_CATEGORY_SALE_AND_PURCHASE_AGREEMENT = {
  key: 'saleAndPurchaseAgreement',
  name: 'Sale and Purchase Agreement',
  category: DOCUMENT_CATEGORY_SECURITY_KEY,
};

export const SUB_CATEGORY_PAYSLIPS = {
  key: 'payslips',
  name: 'Payslips',
  category: DOCUMENT_CATEGORY_INCOME_KEY,
};

export const SUB_CATEGORY_SIGNED_DECLARATION_FORM = {
  key: 'signedDeclarationForm',
  name: 'Signed Declaration Form',
  category: DOCUMENT_CATEGORY_OTHERS_KEY,
};

export const SUB_CATEGORY_OTHER = {
  key: 'other',
  name: 'Other',
  category: DOCUMENT_CATEGORY_OTHERS_KEY,
};

export const DOCUMENT_SUB_CATEGORIES = [
  SUB_CATEGORY_PERSONAL_CURRENT_ACCOUNT,
  SUB_CATEGORY_PASSPORT,
  SUB_CATEGORY_SALE_AND_PURCHASE_AGREEMENT,
  SUB_CATEGORY_PAYSLIPS,
  SUB_CATEGORY_SIGNED_DECLARATION_FORM,
  SUB_CATEGORY_OTHER,
];

export const NZ_OTHER_DOCUMENTS = 101;

export const DOCUMENT_TYPES = [
  {
    subCategory: SUB_CATEGORY_PAYSLIPS.key,
    name: 'Annual Financial Accounts for Year Ended 31st March',
    id: 4,
  },
  {
    subCategory: SUB_CATEGORY_PAYSLIPS.key,
    name: 'Employment Contract or Letter of Offer of Employment',
    id: 3,
  },
  {
    subCategory: SUB_CATEGORY_PAYSLIPS.key,
    name: 'IRD Return Acknowledgement / Notice of Assessment',
    id: 7,
  },
  {
    subCategory: SUB_CATEGORY_PAYSLIPS.key,
    name:
      'Letter from Employer confirming Remuneration, Title and Length of Service',
    id: 2,
  },
  {
    subCategory: SUB_CATEGORY_PAYSLIPS.key,
    name: 'Payslips',
    id: 1,
  },
  {
    subCategory: SUB_CATEGORY_PAYSLIPS.key,
    name: 'Rental Estimate',
    id: 6,
  },
  {
    subCategory: SUB_CATEGORY_PAYSLIPS.key,
    name: 'Tenancy Agreement/s',
    id: 5,
  },
  {
    subCategory: SUB_CATEGORY_SALE_AND_PURCHASE_AGREEMENT.key,
    name: 'Building Report',
    id: 31,
  },
  {
    subCategory: SUB_CATEGORY_SALE_AND_PURCHASE_AGREEMENT.key,
    name: 'Government / Rateable Valuation',
    id: 12,
  },
  {
    subCategory: SUB_CATEGORY_SALE_AND_PURCHASE_AGREEMENT.key,
    name: 'Real Estate Advertising Information',
    id: 11,
  },
  {
    subCategory: SUB_CATEGORY_SALE_AND_PURCHASE_AGREEMENT.key,
    name: 'Registered Valuation',
    id: 9,
  },
  {
    subCategory: SUB_CATEGORY_SALE_AND_PURCHASE_AGREEMENT.key,
    name: 'Sale and Purchase Agreement',
    id: 8,
  },
  {
    subCategory: SUB_CATEGORY_SALE_AND_PURCHASE_AGREEMENT.key,
    name: 'Valuations of Existing Property Owned',
    id: 10,
  },
  {
    subCategory: SUB_CATEGORY_PERSONAL_CURRENT_ACCOUNT.key,
    name: 'Business Bank Account',
    id: 17,
  },
  {
    subCategory: SUB_CATEGORY_PERSONAL_CURRENT_ACCOUNT.key,
    name: 'Credit Card/s',
    id: 15,
  },
  {
    subCategory: SUB_CATEGORY_PERSONAL_CURRENT_ACCOUNT.key,
    name: 'Existing Loan Transaction History Including Latest balance',
    id: 18,
  },
  {
    subCategory: SUB_CATEGORY_PERSONAL_CURRENT_ACCOUNT.key,
    name: 'Gift Declaration',
    id: 33,
  },
  {
    subCategory: SUB_CATEGORY_PERSONAL_CURRENT_ACCOUNT.key,
    name: 'KiwiSaver Confirmation',
    id: 32,
  },
  {
    subCategory: SUB_CATEGORY_PERSONAL_CURRENT_ACCOUNT.key,
    name: 'Personal Current Account',
    id: 13,
  },
  {
    subCategory: SUB_CATEGORY_PERSONAL_CURRENT_ACCOUNT.key,
    name: 'Rental Account',
    id: 16,
  },
  {
    subCategory: SUB_CATEGORY_PERSONAL_CURRENT_ACCOUNT.key,
    name: 'Savings Account',
    id: 14,
  },
  {
    subCategory: SUB_CATEGORY_SIGNED_DECLARATION_FORM.key,
    name: 'Certificate of Incorporation for LTC Company (Investment Property)',
    id: 29,
  },
  {
    subCategory: SUB_CATEGORY_SIGNED_DECLARATION_FORM.key,
    name: 'Credit Reports',
    id: 22,
  },
  {
    subCategory: SUB_CATEGORY_SIGNED_DECLARATION_FORM.key,
    name: 'Evidence of Shares and Other Investments',
    id: 25,
  },
  {
    subCategory: SUB_CATEGORY_SIGNED_DECLARATION_FORM.key,
    name: 'Family Trust Deed',
    id: 28,
  },
  {
    subCategory: SUB_CATEGORY_PASSPORT.key,
    name: 'Identification',
    id: 23,
  },
  {
    subCategory: SUB_CATEGORY_SIGNED_DECLARATION_FORM.key,
    name: 'Lenders Debt Servicing Spreadsheet / Load Worksheet',
    id: 20,
  },
  {
    subCategory: SUB_CATEGORY_SIGNED_DECLARATION_FORM.key,
    name: 'Loan Recommendation / Diary Notes',
    id: 19,
  },
  {
    subCategory: SUB_CATEGORY_SIGNED_DECLARATION_FORM.key,
    name: 'Other Information',
    id: 30,
  },
  {
    subCategory: SUB_CATEGORY_SIGNED_DECLARATION_FORM.key,
    name:
      'Other Personal Loan Statements and Confirmation of Outstanding Balance',
    id: 26,
  },
  {
    subCategory: SUB_CATEGORY_SIGNED_DECLARATION_FORM.key,
    name: 'Quotes for improvement to Security or Written Request from Borrower',
    id: 27,
  },
  {
    subCategory: SUB_CATEGORY_SIGNED_DECLARATION_FORM.key,
    name: 'Signed Declaration Form',
    id: 21,
  },
  {
    subCategory: SUB_CATEGORY_SIGNED_DECLARATION_FORM.key,
    name: 'Superannuation Balance',
    id: 24,
  },
  {
    subCategory: SUB_CATEGORY_OTHER.key,
    name: 'Ignore',
    id: 100,
  },
  {
    subCategory: SUB_CATEGORY_OTHER.key,
    name: 'Other Documents',
    id: NZ_OTHER_DOCUMENTS,
  },
];

export const DOCUMENT_CATEGORIES = [
  DOCUMENT_CATEGORY_IDENTIFICATION_KEY,
  DOCUMENT_CATEGORY_EQUITY_KEY,
  DOCUMENT_CATEGORY_SECURITY_KEY,
  DOCUMENT_CATEGORY_INCOME_KEY,
  DOCUMENT_CATEGORY_OTHERS_KEY,
];
