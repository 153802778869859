export const SET_PAGE_ERROR = 'setPageError';
export const SET_PAGE_GHOST_ERROR = 'setPageGhostError';
export const SET_HANDHOLD_SECTIONS = 'setHandholdSections';
export const SET_FULL_HANDHOLD_SECTIONS = 'setFullHandholdSections';
export const SET_FULL_HANDHOLD_NO_FORESEEABLE_SLUG_SECTIONS =
  'setFullHandholdNoForeseeableSlugSections';
export const CLEAR_HANDHOLD_SECTIONS = 'clearHandholdSections';
export const CLEAR_PAGE_ERROR = 'clearPageError';
export const STOP_FULL_PAGE_SPINNER = 'stopFullPageSpinner';
export const START_FULL_PAGE_SPINNER = 'startFullPageSpinner';
export const TRACK_EVENT = 'trackEvent';
export const SET_NEXT_PATH = 'setNextPath';
export const SET_SAVE_OR_CONTINUE = 'setSaveOrContinue';
export const START_ASYNC_REQUEST = 'startAsyncRequest';
export const END_ASYNC_REQUEST = 'endAsyncRequest';
export const SET_ASYNC_REQUEST_ERROR = 'setAsyncRequestError';
export const CLEAR_ASYNC_REQUEST_ERRORS = 'clearAsyncRequestErrors';
export const SET_ANIMATION_SEQUENCE = 'setAnimationSequence';
export const UNSHIFT_ANIMATION_SEQUENCE = 'unshiftAnimationSequence';
export const SET_NEXT_ANIMATION_SEQUENCE = 'setNextAnimationSequence';
export const SET_APPLICATION_TESTED = 'setApplicationTested';
export const SET_APPLICATION_IS_READ = 'setApplicationIsRead';
export const RESET_APPLICATION_IS_READ = 'resetApplicationIsRead';
export const START_IS_ONE_TIME_PRIVACY_POLICY = 'startIsOneTimePrivacyPolicy';
export const NEXT_ANIMATION_SEQUENCE = 'nextAnimationSequence';
export const START_ANIMATION_SEQUENCE = 'startAnimationSequence';
export const STOP_ANIMATION_SEQUENCE = 'stopAnimationSequence';
export const PAUSE_ANIMATION_SEQUENCE = 'pauseAnimationSequence';
export const SET_PATH_WITH_ANIMATION_SEQUENCE = 'goToPathWithAnimation';
export const SET_HIDE_COMPARE_SELECT_BTN = 'setHideCompareSelectBtn';
export const PUSH_SPINNER = 'pushSpinner';
export const POP_SPINNER = 'popSpinner';
export const REMOVE_SPINNERS = 'removeSpinners';
export const SET_PARTIAL_PROFILE_SECTIONS = 'setPartialProfileSections';
export const INITIALISE = 'initialise';
export const PROCESSING_FORM_POPUP_STATUS = 'processingFormPopupStatus';
export const PROCESSED_FORM_POPUP_STATUS = 'processedFormPopupStatus';
export const RESET_FORM_POPUP_STATUS = 'resetFormPopupStatus';
export const SET_SESSION_STATUS = 'setSessionStatus';
