import { OktaAuth } from '@okta/okta-auth-js';
import md5 from 'md5';
import Cookies from 'universal-cookie';

import { intlMobile } from 'shared/lib/utils';
import LocalStorageProxy from 'lib/localStorageProxy';
import AUTH_INTENTS from 'constants/authIntents';

export const OKTA_API_BASE =
  window.LM_CONFIG.OKTA_API_BASE || 'https://sample-okta-domain.com'; // To handle unit test errors
export const OKTA_CLIENT_ID = window.LM_CONFIG.OKTA_CLIENT_ID;
export const OKTA_AUTH_SERVER = window.LM_CONFIG.OKTA_AUTH_SERVER;
const OKTA_DEVICE_TOKEN_COOKIE = 'oktaDeviceToken';
const MAX_COOKIE_AGE = 94608000; // 3 years

const cookies = new Cookies();

export const getOktaAuth = () => {
  return new OktaAuth({
    clientId: OKTA_CLIENT_ID,
    redirectUri: `${window.location.origin}/authorization-code/callback`,
    useClassicEngine: true,
    issuer: `${OKTA_API_BASE}/oauth2/${OKTA_AUTH_SERVER}`,
    pkce: true,
    url: OKTA_API_BASE,
    tokenManager: {
      storageKey: 'mycrm-tokens',
    },
    transformAuthState: async (oktaAuth, authState) => {
      if (!authState.isAuthenticated || !!authState.oktaUser) {
        return authState;
      }
      const user = await oktaAuth.token.getUserInfo();
      authState.isAuthenticated = !!user;
      authState.oktaUser = user;
      return authState;
    },
  });
};

export const fetchTokens = async (oktaAuth, onError) => {
  try {
    const { tokens } = await oktaAuth.token.getWithoutPrompt({
      responseType: 'code',
      scopes: ['openid', 'profile', 'email'],
    });
    await oktaAuth.tokenManager.setTokens(tokens);
    oktaAuth.authStateManager.updateAuthState();
  } catch (error) {
    onError();
    throw error;
  }
};

export const getAccessToken = async (oktaAuth) => {
  try {
    const token = await oktaAuth.tokenManager.get('accessToken');
    LocalStorageProxy.token = token?.accessToken;
    return token?.accessToken;
  } catch (error) {
    console.warn('An error occurred while fetching accessToken.', error);
    throw error;
  }
};

export const getIdTokenClaims = async (oktaAuth) => {
  try {
    const idToken = await oktaAuth.tokenManager.get('idToken');
    return idToken?.claims;
  } catch (error) {
    console.warn('An error occurred while fetching idToken.', error);
    throw error;
  }
};

export const signOut = async (oktaAuth = getOktaAuth()) => {
  try {
    // Clear tokens first
    await oktaAuth.tokenManager.clear();
    LocalStorageProxy.token = null; // Clear the local storage token

    try {
      // Attempt to revoke tokens, but don't fail if they're already expired
      await oktaAuth
        .revokeAccessToken()
        .catch((error) =>
          console.warn('Access token revocation failed:', error),
        );
      await oktaAuth
        .revokeRefreshToken()
        .catch((error) =>
          console.warn('Refresh token revocation failed:', error),
        );
    } catch (error) {
      console.warn('Token revocation failed:', error);
    }

    // Clean up session
    try {
      await oktaAuth.closeSession();
    } catch (error) {
      console.warn('Session closure failed:', error);
      // Continue with signout even if session closure fails
    }

    await oktaAuth.stop();
  } catch (error) {
    console.warn('An error occurred while signing out:', error);
    // Ensure local cleanup happens even if Okta operations fail
    LocalStorageProxy.token = null;
  }
};

export const buildOktaUser = (scenario, familyId, clientId) => ({
  firstName: scenario.displayName,
  lastName: scenario.lastName,
  email: scenario.email,
  mobilePhone: intlMobile(scenario.mobile),
  familyId,
  clientId,
});

export const generateDeviceToken = () => {
  const deviceToken = md5(
    JSON.stringify({
      userAgent: navigator.userAgent,
      timestamp: new Date().getTime(),
    }),
  );
  cookies.set(OKTA_DEVICE_TOKEN_COOKIE, deviceToken, {
    maxAge: MAX_COOKIE_AGE,
  });
  return deviceToken;
};

export const getDeviceToken = () => {
  const deviceToken = cookies.get(OKTA_DEVICE_TOKEN_COOKIE);
  return deviceToken || generateDeviceToken();
};

export const getStateTokenExpiryHint = (counter, intent) => {
  if (counter < 1) {
    return;
  }
  let intentText = 'log in';
  switch (intent) {
    case AUTH_INTENTS.LOGIN:
    case AUTH_INTENTS.SETUP_FACTOR_AND_LOGIN:
      intentText = 'log in';
      break;
    case AUTH_INTENTS.SIGNUP:
    case AUTH_INTENTS.SHARED:
      intentText = 'sign up';
      break;
    case AUTH_INTENTS.RESET_PASSWORD:
      intentText = 'reset password';
      break;
    default:
      intentText = 'complete';
      break;
  }
  const seconds = Math.floor(counter % 60);
  return `${Math.floor(counter / 60)}.${
    seconds < 10 ? `0${seconds}` : seconds
  } to ${intentText}`;
};

export const getSignInConfig = (
  history,
  recoveryToken,
  stateToken,
  defaultCountryCode = 'AU', // set default country code to Australia
) => ({
  /**
   * Note: when using the Sign-In Widget for an OIDC flow, it still
   * needs to be configured with the base URL for your Okta Org. Here
   * we derive it from the given issuer for convenience.
   */
  el: '#sign-in-widget',
  baseUrl: window.location.origin,
  clientId: OKTA_CLIENT_ID,
  useClassicEngine: true,
  redirectUri: `${window.location.origin}/authorization-code/callback`,
  defaultCountryCode,
  i18n: {
    en: {
      'primaryauth.title': 'Log in to your account',
      'primaryauth.username.placeholder': 'Email',
      'primaryauth.submit': 'Log in',
      'errors.E0000004': 'Incorrect email or password',
      'oform.errorbanner.title': 'Please fill in the correct details',
      'oform.verify': 'Log in',
      'mfa.challenge.verify': 'Log in',
      'rememberDevice.timebased': 'Remember this device for the next {0}',
      'password.reset': 'Save password',
      'enroll.choices.description.generic':
        'Please choose your preferred back up security method',
      needhelp: ' ',
    },
  },
  features: {
    rememberMe: false,
    showPasswordToggleOnSignInPage: true,
    hideSignOutLinkInMFA: true,
    autoPush: true,
    multiOptionalFactorEnroll: true,
  },
  authParams: {
    issuer: `${OKTA_API_BASE}/oauth2/${OKTA_AUTH_SERVER}`,
    scopes: ['openid', 'profile', 'email'],
    display: 'page',
    pkce: true,
    responseMode: 'query',
    responseType: ['id_token', 'token', 'refresh_token'],
  },
  customButtons: [
    {
      title: 'Forgot your password?',
      className: 'btn-customAuth-forgot-password',
      click: () => {
        history.push('/password-recovery');
      },
    },
  ],
  recoveryToken,
  stateToken,
});
