/* eslint-disable sonarjs/no-duplicate-string */
export const EXPENSE_CATEGORY_VEHICLE = { id: 1, name: 'Vehicle' };
export const EXPENSE_CATEGORY_CHILDREN = {
  id: 2,
  name: 'Children & Schooling',
};
export const EXPENSE_CATEGORY_BASIC = { id: 3, name: 'Basic Living Expenses' };
export const EXPENSE_CATEGORY_LOAN = { id: 4, name: 'Credit Cards & Loans' };
export const EXPENSE_CATEGORY_RECREATION = {
  id: 5,
  name: 'Recreation & Holidays',
};
export const EXPENSE_CATEGORY_HOUSEHOLD = { id: 6, name: 'Household Costs' };
export const EXPENSE_CATEGORY_SAVING = { id: 7, name: 'Saving & Insurance' };
export const EXPENSE_CATEGORY_REPAYMENT = {
  id: 8,
  name: 'Mortgage Repayments',
};
export const EXPENSE_CATEGORY_INVESTMENT = { id: 9, name: 'Investments' };
export const EXPENSE_CATEGORY_MOBILE = {
  id: 10,
  name: 'Mobile, Internet & Pay TV',
};
export const EXPENSE_CATEGORY_MISC = { id: 11, name: 'Misc' };

export const EXPENSE_TYPE_VEHICLE_LEASE = {
  id: 1,
  name: 'Lease',
  categoryId: EXPENSE_CATEGORY_VEHICLE.id,
  icon: 'sl-custom-car-2',
};
export const EXPENSE_TYPE_MAINTENENCE = {
  id: 2,
  name: 'Maintenance',
  categoryId: EXPENSE_CATEGORY_VEHICLE.id,
  icon: 'sl-custom-wrench',
};
export const EXPENSE_TYPE_REGISTRATION = {
  id: 3,
  name: 'Registration',
  categoryId: EXPENSE_CATEGORY_VEHICLE.id,
  icon: 'sl-custom-receipt-3',
};
export const EXPENSE_TYPE_PETROL = {
  id: 37,
  name: 'Petrol',
  categoryId: EXPENSE_CATEGORY_VEHICLE.id,
  icon: 'sl-custom-gas-station',
};
export const EXPENSE_TYPE_VEHICLE_OTHER = {
  id: 60,
  name: 'Other',
  categoryId: EXPENSE_CATEGORY_VEHICLE.id,
  icon: 'sl-custom-dollar-currency-3',
  isOther: true,
};

export const EXPENSE_TYPE_CHILD_CARE = {
  id: 5,
  name: 'Child Care',
  categoryId: EXPENSE_CATEGORY_CHILDREN.id,
  icon: 'sl-custom-train',
};
export const EXPENSE_TYPE_PUBLIC_SCHOOL = {
  id: 9,
  name: 'Public School Fees',
  categoryId: EXPENSE_CATEGORY_CHILDREN.id,
  icon: 'sl-custom-academic-cap',
};
export const EXPENSE_TYPE_PRIVATE_SCHOOL = {
  id: 10,
  name: 'Private School Fees',
  categoryId: EXPENSE_CATEGORY_CHILDREN.id,
  icon: 'sl-custom-academic-cap',
};
export const EXPENSE_TYPE_CHILD_MAINTENANCE = {
  id: 54,
  name: 'Child Maintenance',
  categoryId: EXPENSE_CATEGORY_CHILDREN.id,
  icon: 'sl-custom-balance-1',
};
export const EXPENSE_TYPE_CHILDREN_OTHER = {
  id: 6,
  name: 'Other',
  categoryId: EXPENSE_CATEGORY_CHILDREN.id,
  icon: 'sl-custom-dollar-currency-3',
  isOther: true,
}; // called 'Education Expenses' in myCRM

export const EXPENSE_TYPE_CLOTHING = {
  id: 7,
  name: 'Clothing & Footwear',
  categoryId: EXPENSE_CATEGORY_BASIC.id,
  icon: 'sl-custom-shirt-1',
};
export const EXPENSE_TYPE_ELETRICITY = {
  id: 11,
  name: 'Electricity',
  categoryId: EXPENSE_CATEGORY_BASIC.id,
  icon: 'sl-custom-lightbulb-1',
};
export const EXPENSE_TYPE_FOOD = {
  id: 17,
  name: 'Food & Drinks',
  categoryId: EXPENSE_CATEGORY_BASIC.id,
  icon: 'sl-custom-cheese',
};
export const EXPENSE_TYPE_GAS = {
  id: 18,
  name: 'Gas',
  categoryId: EXPENSE_CATEGORY_BASIC.id,
  icon: 'sl-custom-fire',
};
// export const EXPENSE_TYPE_BASIC_LEASE = { id: 29, name: 'Lease', categoryId: EXPENSE_CATEGORY_BASIC.id };
export const EXPENSE_TYPE_PERSONAL_CARE = {
  id: 30,
  name: 'Personal Care',
  categoryId: EXPENSE_CATEGORY_BASIC.id,
  icon: 'sl-custom-scissor-1',
};
export const EXPENSE_TYPE_MEDICAL = {
  id: 36,
  name: 'Medical & Health',
  categoryId: EXPENSE_CATEGORY_BASIC.id,
  icon: 'sl-custom-ambulance',
};
export const EXPENSE_TYPE_TRANSPORT = {
  id: 38,
  name: 'Transport',
  categoryId: EXPENSE_CATEGORY_BASIC.id,
  icon: 'sl-custom-bus-1',
};
export const EXPENSE_TYPE_RENT = {
  id: 40,
  name: 'Rent',
  categoryId: EXPENSE_CATEGORY_BASIC.id,
  icon: 'sl-custom-house-4',
};
export const EXPENSE_TYPE_BOARD = {
  id: 41,
  name: 'Board',
  categoryId: EXPENSE_CATEGORY_BASIC.id,
  icon: 'sl-custom-house-2',
};
export const EXPENSE_TYPE_BASIC_OTHER = {
  id: 59,
  name: 'Other',
  categoryId: EXPENSE_CATEGORY_BASIC.id,
  icon: 'sl-custom-dollar-currency-3',
  isOther: true,
};

export const EXPENSE_TYPE_TRAVEL = {
  id: 12,
  name: 'Travel / Holidays',
  categoryId: EXPENSE_CATEGORY_RECREATION.id,
  icon: 'sl-custom-beach',
};
export const EXPENSE_TYPE_CINEMA = {
  id: 13,
  name: 'Cinema / Concerts',
  categoryId: EXPENSE_CATEGORY_RECREATION.id,
  icon: 'sl-custom-smileys',
};
export const EXPENSE_TYPE_THERAPIES = {
  id: 14,
  name: 'Natural Therapies',
  categoryId: EXPENSE_CATEGORY_RECREATION.id,
  icon: 'sl-custom-yin-yang',
};
export const EXPENSE_TYPE_ALCOHOL = {
  id: 15,
  name: 'Alcohol',
  categoryId: EXPENSE_CATEGORY_RECREATION.id,
  icon: 'sl-custom-wine',
};
export const EXPENSE_TYPE_GYM = {
  id: 46,
  name: 'Gym / Sports',
  categoryId: EXPENSE_CATEGORY_RECREATION.id,
  icon: 'sl-custom-mountain-bike',
};
export const EXPENSE_TYPE_RECREATION_OTHER = {
  id: 16,
  name: 'Other',
  categoryId: EXPENSE_CATEGORY_RECREATION.id,
  icon: 'sl-custom-dollar-currency-3',
  isOther: true,
}; // called 'Entertainment' in myCRM

export const EXPENSE_TYPE_WATER = {
  id: 19,
  name: 'Water & Sewer',
  categoryId: EXPENSE_CATEGORY_HOUSEHOLD.id,
  icon: 'sl-custom-blood',
};
export const EXPENSE_TYPE_REPAIRS = {
  id: 20,
  name: 'Repairs and Maintenance',
  categoryId: EXPENSE_CATEGORY_HOUSEHOLD.id,
  icon: 'sl-custom-driller',
};
export const EXPENSE_TYPE_BODY_CORPORATE = {
  id: 21,
  name: 'Body Corporate',
  categoryId: EXPENSE_CATEGORY_HOUSEHOLD.id,
  icon: 'sl-custom-receipt-4',
};
export const EXPENSE_TYPE_FURNISHINGS = {
  id: 22,
  name: 'Furnishings & Electrical',
  categoryId: EXPENSE_CATEGORY_HOUSEHOLD.id,
  icon: 'sl-custom-sofa-1',
};
export const EXPENSE_TYPE_HOME_OPERATION = {
  id: 23,
  name: 'Home Operation',
  categoryId: EXPENSE_CATEGORY_HOUSEHOLD.id,
  icon: 'sl-custom-watering-can',
};
export const EXPENSE_TYPE_PETS = {
  id: 24,
  name: 'Pets',
  categoryId: EXPENSE_CATEGORY_HOUSEHOLD.id,
  icon: 'sl-custom-bug-1',
};
export const EXPENSE_TYPE_COUNCIL_RATES = {
  id: 39,
  name: 'Council Rates',
  categoryId: EXPENSE_CATEGORY_HOUSEHOLD.id,
  icon: 'sl-custom-script',
};
export const EXPENSE_TYPE_HOUSEHOLD_OTHER = {
  id: 25,
  name: 'Other',
  categoryId: EXPENSE_CATEGORY_HOUSEHOLD.id,
  icon: 'sl-custom-dollar-currency-3',
  isOther: true,
};

export const EXPENSE_TYPE_HOME_INSURANCE = {
  id: 26,
  name: 'Home Insurance',
  categoryId: EXPENSE_CATEGORY_SAVING.id,
  icon: 'sl-custom-campfire',
};
export const EXPENSE_TYPE_LIFE_INSURANCE = {
  id: 27,
  name: 'Life Insurance',
  categoryId: EXPENSE_CATEGORY_SAVING.id,
  icon: 'sl-custom-skull-1',
};
export const EXPENSE_TYPE_VOLUNTARY_SAVING = {
  id: 49,
  name: 'Voluntary Saving',
  categoryId: EXPENSE_CATEGORY_SAVING.id,
  icon: 'sl-custom-piggy-bank',
};
export const EXPENSE_TYPE_VOLUNTARY_SUPER = {
  id: 50,
  name: 'Voluntary Super',
  categoryId: EXPENSE_CATEGORY_SAVING.id,
  availableCountries: ['AU'],
  icon: 'sl-custom-dollar-bag',
};
export const EXPENSE_TYPE_KIWISAVER = {
  id: 51,
  name: 'KiwiSaver',
  categoryId: EXPENSE_CATEGORY_SAVING.id,
  availableCountries: ['NZ'],
  icon: 'sl-custom-dollar-bag',
};
export const EXPENSE_TYPE_VEHICLE_INSURANCE = {
  id: 55,
  name: 'Vehicle Insurance',
  categoryId: EXPENSE_CATEGORY_SAVING.id,
  icon: 'sl-custom-car-4',
};
export const EXPENSE_TYPE_HEALTH_INSURANCE = {
  id: 56,
  name: 'Health Insurance',
  categoryId: EXPENSE_CATEGORY_SAVING.id,
  icon: 'sl-custom-stethoscope',
};
export const EXPENSE_TYPE_SUPER_INSURANCE = {
  id: 57,
  name: 'Superannuation Insurance',
  categoryId: EXPENSE_CATEGORY_SAVING.id,
  icon: 'sl-custom-heart-pulse',
};
export const EXPENSE_TYPE_BUSINESS_INSURANCE = {
  id: 58,
  name: 'Business Insurance',
  categoryId: EXPENSE_CATEGORY_SAVING.id,
  icon: 'sl-custom-chart-board',
};
export const EXPENSE_TYPE_SAVING_OTHER = {
  id: 28,
  name: 'Other',
  categoryId: EXPENSE_CATEGORY_SAVING.id,
  icon: 'sl-custom-dollar-currency-3',
  isOther: true,
}; // called 'Insurance' in myCRM

export const EXPENSE_TYPE_INVESTMENT_PROPERTY = {
  id: 32,
  name: 'Investment Property',
  categoryId: EXPENSE_CATEGORY_INVESTMENT.id,
  icon: 'sl-custom-building-3',
};
export const EXPENSE_TYPE_OTHER_INVESTMENT = {
  id: 48,
  name: 'Other Investment Costs',
  categoryId: EXPENSE_CATEGORY_INVESTMENT.id,
  icon: 'sl-custom-dollar-currency-3',
  isOther: true,
};

export const EXPENSE_TYPE_INTERNET = {
  id: 43,
  name: 'Internet & Pay TV',
  categoryId: EXPENSE_CATEGORY_MOBILE.id,
  icon: 'sl-custom-wireless-router-1',
};
export const EXPENSE_TYPE_NETFLIX = {
  id: 44,
  name: 'Netflix iTunes etc',
  categoryId: EXPENSE_CATEGORY_MOBILE.id,
  icon: 'sl-custom-music-note-3',
};
export const EXPENSE_TYPE_MOBILE = {
  id: 47,
  name: 'Mobile Phones',
  categoryId: EXPENSE_CATEGORY_MOBILE.id,
  icon: 'sl-custom-mobile-phone-1',
};
export const EXPENSE_TYPE_MOBILE_OTHER = {
  id: 45,
  name: 'Other',
  categoryId: EXPENSE_CATEGORY_MOBILE.id,
  icon: 'sl-custom-dollar-currency-3',
  isOther: true,
};

export const EXPENSE_TYPE_DONATION = {
  id: 52,
  name: 'Regular Donations / Tithing',
  categoryId: EXPENSE_CATEGORY_MISC.id,
  icon: 'sl-custom-present',
};
export const EXPENSE_TYPE_MISC_OTHER = {
  id: 53,
  name: 'Other',
  categoryId: EXPENSE_CATEGORY_MISC.id,
  icon: 'sl-custom-dollar-currency-3',
  isOther: true,
};

export const EXPENSES_TYPES_PER_CATEGORY = [
  {
    ...EXPENSE_CATEGORY_BASIC,
    types: [
      EXPENSE_TYPE_FOOD,
      EXPENSE_TYPE_CLOTHING,
      EXPENSE_TYPE_MEDICAL,
      EXPENSE_TYPE_ELETRICITY,
      EXPENSE_TYPE_GAS,
      EXPENSE_TYPE_PERSONAL_CARE,
      EXPENSE_TYPE_RENT,
      EXPENSE_TYPE_BOARD,
      EXPENSE_TYPE_TRANSPORT,
      EXPENSE_TYPE_BASIC_OTHER,
    ],
  },
  {
    ...EXPENSE_CATEGORY_HOUSEHOLD,
    types: [
      EXPENSE_TYPE_WATER,
      EXPENSE_TYPE_COUNCIL_RATES,
      EXPENSE_TYPE_REPAIRS,
      EXPENSE_TYPE_BODY_CORPORATE,
      EXPENSE_TYPE_FURNISHINGS,
      EXPENSE_TYPE_HOME_OPERATION,
      EXPENSE_TYPE_PETS,
      EXPENSE_TYPE_HOUSEHOLD_OTHER,
    ],
  },
  {
    ...EXPENSE_CATEGORY_VEHICLE,
    types: [
      EXPENSE_TYPE_PETROL,
      EXPENSE_TYPE_REGISTRATION,
      EXPENSE_TYPE_MAINTENENCE,
      EXPENSE_TYPE_VEHICLE_LEASE,
      EXPENSE_TYPE_VEHICLE_OTHER,
    ],
  },
  {
    ...EXPENSE_CATEGORY_RECREATION,
    types: [
      EXPENSE_TYPE_TRAVEL,
      EXPENSE_TYPE_GYM,
      EXPENSE_TYPE_CINEMA,
      EXPENSE_TYPE_THERAPIES,
      EXPENSE_TYPE_ALCOHOL,
      EXPENSE_TYPE_RECREATION_OTHER,
    ],
  },
  {
    ...EXPENSE_CATEGORY_CHILDREN,
    types: [
      EXPENSE_TYPE_CHILD_CARE,
      EXPENSE_TYPE_PUBLIC_SCHOOL,
      EXPENSE_TYPE_PRIVATE_SCHOOL,
      EXPENSE_TYPE_CHILD_MAINTENANCE,
      EXPENSE_TYPE_CHILDREN_OTHER,
    ],
  },
  {
    ...EXPENSE_CATEGORY_MOBILE,
    types: [
      EXPENSE_TYPE_INTERNET,
      EXPENSE_TYPE_NETFLIX,
      EXPENSE_TYPE_MOBILE,
      EXPENSE_TYPE_MOBILE_OTHER,
    ],
  },
  {
    ...EXPENSE_CATEGORY_SAVING,
    types: [
      EXPENSE_TYPE_HOME_INSURANCE,
      EXPENSE_TYPE_LIFE_INSURANCE,
      EXPENSE_TYPE_VOLUNTARY_SAVING,
      EXPENSE_TYPE_VOLUNTARY_SUPER,
      EXPENSE_TYPE_KIWISAVER,
      EXPENSE_TYPE_VEHICLE_INSURANCE,
      EXPENSE_TYPE_HEALTH_INSURANCE,
      EXPENSE_TYPE_SUPER_INSURANCE,
      EXPENSE_TYPE_BUSINESS_INSURANCE,
      EXPENSE_TYPE_SAVING_OTHER,
    ],
  },
  {
    ...EXPENSE_CATEGORY_MISC,
    types: [EXPENSE_TYPE_DONATION, EXPENSE_TYPE_MISC_OTHER],
  },
  {
    ...EXPENSE_CATEGORY_INVESTMENT,
    types: [EXPENSE_TYPE_INVESTMENT_PROPERTY, EXPENSE_TYPE_OTHER_INVESTMENT],
  },
];

export const EXPENSE_TYPE_CREDIT_CARDS = { id: 8, name: 'Credit Cards' };
export const EXPENSE_TYPE_PERSONAL_LOAN = { id: 33, name: 'Personal Loan' };
export const EXPENSE_TYPE_VEHICLE_LOAN = { id: 34, name: 'Vehicle Loan' };
export const EXPENSE_TYPE_STUDENT_LOAN = { id: 35, name: 'Student Loan' };
export const EXPENSE_TYPE_STORE_CARDS = { id: 42, name: 'Store Cards' };
export const EXPENSE_TYPE_REPAYMENT = { id: 31, name: 'Mortgage Repayments' };

/* not using this just for reference */
// const LIABILITY_EXPENSE_TYPES = [
//   EXPENSE_TYPE_CREDIT_CARDS,
//   EXPENSE_TYPE_PERSONAL_LOAN,
//   EXPENSE_TYPE_VEHICLE_LOAN,
//   EXPENSE_TYPE_STUDENT_LOAN,
//   EXPENSE_TYPE_STORE_CARDS,
//   EXPENSE_TYPE_REPAYMENT,
// ];
// const LIABILITY_EXPENSE_TYPES_PER_CATEGORY = {
//   [EXPENSE_CATEGORY_LOAN.id]: [
//     EXPENSE_TYPE_CREDIT_CARDS,
//     EXPENSE_TYPE_PERSONAL_LOAN,
//     EXPENSE_TYPE_VEHICLE_LOAN,
//     EXPENSE_TYPE_STUDENT_LOAN,
//     EXPENSE_TYPE_STORE_CARDS,
//   ],
//   [EXPENSE_CATEGORY_REPAYMENT.id]: [
//     EXPENSE_TYPE_REPAYMENT,
//   ],
// };
