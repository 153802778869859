import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button/Button';
import styles from './SessionLogout.css';

const SessionLogout = ({ onLoginClick }) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>You have been logged out</h1>
      <p className={styles.message}>
        Your session has expired, and you have been logged out for security
        reasons
      </p>
      <Button
        onClick={onLoginClick}
        theme='buttonNext'
        className={styles.loginButton}
      >
        Log back in
      </Button>
    </div>
  );
};

SessionLogout.propTypes = {
  onLoginClick: PropTypes.func.isRequired,
};

export default SessionLogout;
