export const properties = {
  TOKEN: 'token',
  DISPLAY_NAME: 'displayName',
  ADVISOR_ORG_SLUG: 'advisorOrgSlug',
  LOGIN_AS_LOAN_APPLICATION_ID: 'loginAsLoanApplicationId',
  BROKER_LOGIN_USERNAME: 'brokerLoginUsername',
  PRIMARY_LOAN_APPLICATION_ID: 'primaryLoanApplicationId',
  LOGIN_AS_CLIENT_ID: 'loginAsClientId',
  CURRENT_CLIENT_ID: 'currentClientId',
  BROKER_FAMILY_ID: 'brokerFamilyId',
  SELECTED_CLIENT_ID: 'selectedClientId',
  LOOP_DETECTION_COUNTER: 'loopDetectionCounter',
  LOGOUT_REDIRECT_URI: 'logoutRedirectUri',
  SENTRY: 'sentry',
  PARTIAL_PROFILE_SECTIONS: 'partialProfileSections',
  OKTA_SECURE_ROUTER_REFERRER_PATH: 'secureRouterReferrerPath',
  OKTA_CACHE_STORAGE: 'okta-cache-storage',
  OKTA_TOKEN_STORAGE: 'okta-token-storage',
  OKTA_PKCE_STORAGE: 'okta-pkce-storage',
  OKTA_FACTOR_ID: 'factorId',
  OKTA_STATE_TOKEN: 'stateToken',
  ROX_CACHE_CLIENT_DATA: 'client_data',
  ROX_CACHE_DISTINCT_ID: 'lscache-distinctId',
  ROX_LOGGER: 'loglevel:ROXLogger',
  LOGIN_CURRENT_PATH: 'loginCurrentPath',
  ADVISER_UUID: 'adviserUuid',
  SHOW_CONTINUE_SESSION: 'showContinueSession',
};

export const SESSION_EXEMPTED_PROPS = [
  properties.TOKEN,
  properties.CURRENT_CLIENT_ID,
  properties.BROKER_FAMILY_ID,
  properties.SELECTED_CLIENT_ID,
  properties.LOOP_DETECTION_COUNTER,
];

export const GOAL_EXEMPTED_PROPS = [
  properties.LOGIN_AS_LOAN_APPLICATION_ID,
  properties.PRIMARY_LOAN_APPLICATION_ID,
];

export const CLEAR_EXEMPTED_PROPS = [
  properties.LOGOUT_REDIRECT_URI,
  properties.SHOW_CONTINUE_SESSION,
];

const LocalStorageProxy = {
  clearAll() {
    Object.values(properties).forEach(
      (property) =>
        !CLEAR_EXEMPTED_PROPS.includes(property) &&
        localStorage.removeItem(property),
    );
  },
  clearAllExcept(exceptions) {
    Object.values(properties).forEach(
      (property) =>
        !exceptions.includes(property) && localStorage.removeItem(property),
    );
  },
  clear(toRemove) {
    localStorage.removeItem(toRemove);
  },
};

Object.values(properties).forEach((property) => {
  Object.defineProperty(LocalStorageProxy, property, {
    get: () => {
      try {
        return localStorage.getItem(property);
      } catch (error) {
        console.log('WARNING:', error);
        return null;
      }
    },
    set: (value) => {
      try {
        if (value === undefined) {
          localStorage.removeItem(property);
        } else {
          localStorage.setItem(property, value);
        }
      } catch (error) {
        console.log('WARNING:', error);
      }
    },
  });
});

export default LocalStorageProxy;
